import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    avatar: '',
    userName: '',
    email: '',
    authority: [''],
}


export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => {
            const { profile_picture, name, email ,country_id,token,contact,dob,gender,role,id,location_id} = action.payload;
            return {
              id: id || '',
              avatar: profile_picture || '',
              userName: name || 'Anonymous',
              authority: [role],
              email: email || '',
              country_code:country_id,
              contact:contact,
              location_id:location_id,
              token:token,
            };

          },

        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export default userSlice.reducer
