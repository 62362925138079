
const API_URL = process.env.REACT_APP_API_URL;

const appConfig = {
    apiPrefix: API_URL,
    authenticatedEntryPath: 'app/Lead/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig

